/* REMINDER
 * To size a mesh to its texture:
 * mesh.scale.x = material.map.source.data.naturalWidth
 * mesh.scale.y = material.map.source.data.naturalHeight
*/

import * as THREE from "three";
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';

gsap.defaults({ overwrite: 'auto' });

function remap(number, inMin, inMax, outMin, outMax) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

const NUM_TEXTURES = 4;
const ICON_X_LOC = -0.4;
const CHECK_LOCS = { x: 0.8, y: 0.4, off: 0.125 }
let iconAITexture, iconCloudTexture, iconDBTexture, iconCheckTexture;
let iconIndex = 0;
let textureCount = 0;
const vpTextureLoader = new THREE.TextureLoader();
vpTextureLoader.load(new URL('./valueprop-icon-ai.png', import.meta.url), function (texture) {
    texture.colorSpace = THREE.SRGBColorSpace;
    iconAITexture = texture;
    buildIcons();
});
vpTextureLoader.load(new URL('./valueprop-icon-cloud.png', import.meta.url), function (texture) {
    texture.colorSpace = THREE.SRGBColorSpace;
    iconCloudTexture = texture;
    buildIcons();
});
vpTextureLoader.load(new URL('./valueprop-icon-database.png', import.meta.url), function (texture) {
    texture.colorSpace = THREE.SRGBColorSpace;
    iconDBTexture = texture;
    buildIcons();
});
vpTextureLoader.load(new URL('./valueprop-icon-check.png', import.meta.url), function (texture) {
    texture.colorSpace = THREE.SRGBColorSpace;
    iconCheckTexture = texture;
    buildIcons();
});

const MESH_D = 0.25;
const SHAPE_D = 0.5;
const MESH_S = 1.35;
let opacityItems = [];
let meshCheck1, meshCheck2, meshCheck3;
let iconMeshesGroup = new THREE.Group();
const buildIcons = () => {
    textureCount++;
    if (textureCount < NUM_TEXTURES) { return; }
    containerGroup.add(iconMeshesGroup);
    let geom = new THREE.PlaneGeometry(MESH_D, MESH_D, 8, 8);
    let mat = new THREE.MeshBasicMaterial({ transparent: true, map: iconAITexture });
    mat.opacity = 0;
    iconMeshesGroup.add(new THREE.Mesh(geom, mat));
    geom = new THREE.PlaneGeometry(MESH_D, MESH_D, 8, 8);
    mat = new THREE.MeshBasicMaterial({ transparent: true, map: iconCloudTexture });
    mat.opacity = 0;
    iconMeshesGroup.add(new THREE.Mesh(geom, mat));
    geom = new THREE.PlaneGeometry(MESH_D, MESH_D, 8, 8);
    mat = new THREE.MeshBasicMaterial({ transparent: true, map: iconDBTexture });
    mat.opacity = 0;
    iconMeshesGroup.add(new THREE.Mesh(geom, mat));
    geom = new THREE.PlaneGeometry(MESH_D * 3, MESH_D * 3, 8, 8);
    mat = new THREE.MeshBasicMaterial({ transparent: true, map: iconCheckTexture });
    mat.opacity = 0;
    meshCheck1 = new THREE.Mesh(geom, mat);
    meshCheck1.material.opacity = 0;
    containerGroup.add(meshCheck1);
    geom = new THREE.PlaneGeometry(MESH_D * 3, MESH_D * 3, 8, 8);
    mat = new THREE.MeshBasicMaterial({ transparent: true, map: iconCheckTexture });
    mat.opacity = 0;
    meshCheck2 = new THREE.Mesh(geom, mat);
    meshCheck2.material.opacity = 0;
    containerGroup.add(meshCheck2);
    geom = new THREE.PlaneGeometry(MESH_D * 3, MESH_D * 3, 8, 8);
    mat = new THREE.MeshBasicMaterial({ transparent: true, map: iconCheckTexture });
    mat.opacity = 0;
    meshCheck3 = new THREE.Mesh(geom, mat);
    meshCheck3.material.opacity = 0;
    containerGroup.add(meshCheck3);

    iconMeshesGroup.children[0].scale.set(MESH_S, MESH_S, MESH_S);
    iconMeshesGroup.children[1].scale.set(MESH_S, MESH_S, MESH_S);
    iconMeshesGroup.children[2].scale.set(MESH_S, MESH_S, MESH_S);

    meshCheck1.position.set(CHECK_LOCS.x, CHECK_LOCS.y + CHECK_LOCS.off);
    meshCheck2.position.set(CHECK_LOCS.x, CHECK_LOCS.off);
    meshCheck3.position.set(CHECK_LOCS.x, -CHECK_LOCS.y + CHECK_LOCS.off);

    loadFrameLines();
    loadFrames();
    loadMorphShapes();
    setupTimelines();
    handleMobile();

    const pBar = document.querySelector(`#s-value-prop-1 .value-prop-progress-container${isMobile() ? '-mobile' : ''} .progress-active`);
    pBar.style.width = `0%`;
}

const xOffset = -1;
export const uberGroup = new THREE.Group();
const containerGroup = new THREE.Group();
export const loadValueProps = (scene) => {
    containerGroup.position.x = xOffset;
    uberGroup.add(containerGroup);
    scene.add(uberGroup);
}

export const hideValueProps = (scene) => {
    scene.remove(uberGroup);
    uberGroup.traverse((child) => {
        if (child instanceof THREE.Mesh) {
            child.geometry.dispose();
            child.material.dispose();
        }
    })
}

const numShapes = 6;
let props = { tension: 0, baseTension: 0, targetTension: 0.8, lineWidth: 2 };
let square, morphShapeGeometry;
let morphShapesGroup = new THREE.Group();
morphShapesGroup.position.z = 1;
morphShapesGroup.position.x = 0.15;
const loadMorphShapes = () => {
    square = new THREE.CatmullRomCurve3([
        new THREE.Vector3(-SHAPE_D, SHAPE_D, 0),
        new THREE.Vector3(SHAPE_D, SHAPE_D, 0),
        new THREE.Vector3(SHAPE_D, -SHAPE_D, 0),
        new THREE.Vector3(-SHAPE_D, -SHAPE_D, 0)]);
    square.closed = true;
    square.curveType = 'catmullrom';
    square.tension = props.baseTension;
    const positions = getUpdatedPositions(square);
    morphShapeGeometry = new LineGeometry();
    morphShapeGeometry.setPositions(positions);
    for (let i = 0; i < numShapes; i++) {
        const matLine = new LineMaterial({
            color: 0xffffff,
            linewidth: props.lineWidth,
            transparent: true,
        });
        matLine.opacity = 0;
        const shape = new Line2(morphShapeGeometry, matLine);
        morphShapesGroup.add(shape);
        if (i == 1) {
            morphShapesGroup.children[i].scale.set(props.targetTension, props.targetTension, props.targetTension);
        }
    }
    containerGroup.add(morphShapesGroup);
}

const numFrames = 3;
const FRAME_D = 0.17;
let framesGroup = new THREE.Group();
const loadFrames = () => {
    for (let i = 0; i < numFrames; i++) {
        const square = new THREE.CatmullRomCurve3([
            new THREE.Vector3(-FRAME_D - ICON_X_LOC, FRAME_D, 0),
            new THREE.Vector3(FRAME_D - ICON_X_LOC, FRAME_D, 0),
            new THREE.Vector3(FRAME_D - ICON_X_LOC, -FRAME_D, 0),
            new THREE.Vector3(-FRAME_D - ICON_X_LOC, -FRAME_D, 0)]);
        square.closed = true;
        square.curveType = 'catmullrom';
        square.tension = props.baseTension;
        const positions = getUpdatedPositions(square);
        const morphShapeGeometry = new LineGeometry();
        morphShapeGeometry.setPositions(positions);
        const matLine = new LineMaterial({
            color: 0x3d3c40,
            linewidth: props.lineWidth,
            transparent: true,
        });
        const shape = new Line2(morphShapeGeometry, matLine);
        matLine.opacity = 0;
        shape.position.y = (i == 0 ? CHECK_LOCS.y : (i == 1 ? 0 : -CHECK_LOCS.y));
        framesGroup.add(shape);
    }
    framesGroup.position.x = ICON_X_LOC * 1.4;
    containerGroup.add(framesGroup);
}

const numFramesLines = 2;
let frameLinesGroup = new THREE.Group();
const loadFrameLines = () => {
    for (let i = 0; i < numFrames; i++) {
        const innerGroup = new THREE.Group();
        for (let j = 0; j < numFramesLines; j++) {
            const square = new THREE.CatmullRomCurve3([
                new THREE.Vector3(-FRAME_D - ICON_X_LOC, FRAME_D, 0),
                new THREE.Vector3(FRAME_D - ICON_X_LOC * 2, FRAME_D, 0)]);
            square.closed = true;
            square.curveType = 'catmullrom';
            square.tension = props.baseTension;
            const positions = getUpdatedPositions(square);
            const morphShapeGeometry = new LineGeometry();
            morphShapeGeometry.setPositions(positions, 2);
            const matLine = new LineMaterial({
                color: 0x3d3c40,
                linewidth: props.lineWidth * 6,
                transparent: true,
            });
            matLine.opacity = 0;
            const shape = new Line2(morphShapeGeometry, matLine);
            shape.position.x = j == 0 ? 0.12 : 0.21;
            shape.scale.x = j == 0 ? 1 : 0.6;
            shape.position.y = j == 0 ? 0 : -0.08;
            innerGroup.add(shape);
        }
        innerGroup.position.y = (i == 0 ? CHECK_LOCS.y : (i == 1 ? 0 : -CHECK_LOCS.y));
        frameLinesGroup.add(innerGroup);
    }
    frameLinesGroup.position.x = ICON_X_LOC * 1.4;
    frameLinesGroup.position.y = -CHECK_LOCS.y * 0.3;
    containerGroup.add(frameLinesGroup);
}


let prevPhase = -1;
export const animateValueProps = (phase, progress) => {
    // console.log(phase, progress.toFixed(1))
    animateProgressBar(phase, progress);
    if (prevPhase != phase) {
        const scrollingForward = prevPhase < phase;
        // console.log(`phase change from ${prevPhase} to ${phase}`)
        updateOpacityItems(phase == 0);
        setArrangement(phase, scrollingForward);
        prevPhase = phase;
    }
    switch (phase) {
        case 0:
            setOpacity((progress > 0.75 ? remap(progress, 0.75, 1, 0, 1) : 0));
            break;
        case 4:
            setOpacity((progress < 0.25 ? remap(progress, 0, 0.25, 1, 0) : 0));
            break;
    }
}

const animateProgressBar = (phase, progress) => {
    if (phase < 1 || phase > 3) { return; }
    const pBar = document.querySelector(`#s-value-prop-${phase} .value-prop-progress-container${isMobile() ? '-mobile' : ''} .progress-active`);
    pBar.style.width = `${progress.toFixed(2) * 100}%`;
}

const setArrangement = (phase, scrollingForward) => {
    // console.log(`phase: ${phase}, forward: ${scrollingForward}`);
    switch (phase) {
        case 0:
            if(!scrollingForward){
                if(arrangement3MorphShapes.isActive()){
                    arrangement3MorphShapes.progress(0);
                    arrangement3MorphShapes.pause();
                }
                if(arrangement2MorphShapes.isActive()){
                    arrangement2MorphShapes.progress(0);
                    arrangement2MorphShapes.pause();
                }
                clearArrangement1Loops();
                hardResetMorphShapes(false);
            }
            break;
        case 1:
            if (scrollingForward) {
                arrangement1SquareLoop.restart();
            }
            else {
                if (arrangement3Icons.isActive()) {
                    arrangement3Icons.progress(0);
                    arrangement3Icons.pause();
                }
                arrangement2MorphShapes.reverse();
                window.setTimeout(() => { if (prevPhase == 1) { arrangement1SquareLoop.restart(); } }, tlDurations.arrangement2MorphShapes * 1000);
                arrangement2Icons.pause();
                arrangement2IconsZLoop.pause();
                arrangement1Icons.restart();
            }
            break;
        case 2:
            iconIndex = 0;
            if (scrollingForward) {
                clearArrangement1Loops();
                arrangement2MorphShapes.play(0);
                arrangement2Icons.play(0);
                arrangement2IconsZLoop.play(0);
            }
            else {
                if (arrangement3Frames.isActive()) { arrangement3Frames.pause(); }
                arrangement3MorphShapes.reverse();
                arrangement3Icons.reverse();
                window.setTimeout(() => { if (prevPhase == 2) { arrangement2IconsZLoop.play(0); } }, tlDurations.arrangement3Icons * 1000);
                arrangement3FramesHide.restart();
            }
            break;
        case 3:
            if (scrollingForward) {
                arrangement2MorphShapes.progress(1);
                arrangement2MorphShapes.pause();
                arrangement2Icons.progress(1);
                arrangement2Icons.pause();
                arrangement2IconsZLoop.progress(0);
                arrangement2IconsZLoop.pause();
                arrangement3MorphShapes.restart();
                arrangement3Icons.restart();
                arrangement3Frames.restart();
            }
            break;
        case 4:
            if (arrangement3Frames.isActive()) { arrangement3Frames.progress(1); }
            break;
    }
}

let arrangement1SquareLoop, arrangement1Icon0Loop, arrangement1Icon1Loop, arrangement1Icons, arrangement1Icon2Loop, arrangement2MorphShapes, arrangement2MorphShapeGroupRotation, arrangement2Icons, arrangement2IconsZLoop, arrangement3MorphShapes, arrangement3Icons, arrangement3Frames, arrangement3FramesHide;
const tlDurations = { arrangement2MorphShapes: 0.3, arrangement3MorphShapes: 0.3, arrangement3Icons: 0.45 };
const FRAME_SCALE = 4.5;
gsap.defaults({ overwrite: 'true' });
const setupTimelines = () => {
    arrangement1Icon2Loop = gsap.timeline();
    arrangement1Icon2Loop.pause();
    arrangement1Icon2Loop.fromTo(iconMeshesGroup.children[2].rotation, { y: 0 }, { y: -Math.PI / 180 * 180, duration: 0.5, ease: 'power1.easeIn' }, 1);
    arrangement1Icon2Loop.to(iconMeshesGroup.children[2].material, { opacity: 0, duration: 0, delay: 0.5 }, 1);
    arrangement1Icon2Loop.to(iconMeshesGroup.children[0].material, { opacity: 1, duration: 0, delay: 0 }, 1);
    arrangement1Icon2Loop.fromTo(iconMeshesGroup.children[0].rotation, { y: Math.PI / 180 * 180 }, { y: 0, duration: 0.5, ease: 'power1.easeOut' }, 1);
    arrangement1Icon1Loop = gsap.timeline();
    arrangement1Icon1Loop.pause();
    arrangement1Icon1Loop.fromTo(iconMeshesGroup.children[1].rotation, { y: 0 }, { y: -Math.PI / 180 * 180, duration: 0.5, ease: 'power1.easeIn' }, 1);
    arrangement1Icon1Loop.to(iconMeshesGroup.children[1].material, { opacity: 0, duration: 0, delay: 0.5 }, 1);
    arrangement1Icon1Loop.to(iconMeshesGroup.children[2].material, { opacity: 1, duration: 0, delay: 0 }, 1);
    arrangement1Icon1Loop.fromTo(iconMeshesGroup.children[2].rotation, { y: Math.PI / 180 * 180 }, { y: 0, duration: 0.5, ease: 'power1.easeOut' }, 1);
    arrangement1Icon0Loop = gsap.timeline();
    arrangement1Icon0Loop.pause();
    arrangement1Icon0Loop.fromTo(iconMeshesGroup.children[0].rotation, { y: 0 }, { y: -Math.PI / 180 * 180, duration: 0.5, ease: 'power1.easeIn' }, 1);
    arrangement1Icon0Loop.to(iconMeshesGroup.children[0].material, { opacity: 0, duration: 0, delay: 0.5 }, 1);
    arrangement1Icon0Loop.to(iconMeshesGroup.children[1].material, { opacity: 1, duration: 0, delay: 0 }, 1);
    arrangement1Icon0Loop.fromTo(iconMeshesGroup.children[1].rotation, { y: Math.PI / 180 * 180 }, { y: 0, duration: 0.5, ease: 'power1.easeOut' }, 1);
    arrangement1SquareLoop = gsap.timeline({ repeat: -1, repeatDelay: 0.5 });
    arrangement1SquareLoop.pause();
    arrangement1SquareLoop.to(morphShapesGroup.children[0].rotation, { y: -Math.PI / 180 * 180, ease: 'back.inOut(2)', duration: 1.5, delay: 0.5 });
    arrangement1SquareLoop.to(morphShapesGroup.children[1].rotation, {
        y: -Math.PI / 180 * 180, ease: 'back.inOut(2)', duration: 2, onStart: () => {
            switch (iconIndex) {
                case 0:
                    arrangement1Icon0Loop.play(0);
                    iconIndex = 1;
                    break;
                case 1:
                    arrangement1Icon1Loop.play(0);
                    iconIndex = 2;
                    break;
                case 2:
                    arrangement1Icon2Loop.play(0);
                    iconIndex = 0;
                    break;
            }
        }
    }, 0);

    arrangement1Icons = gsap.timeline();
    arrangement1Icons.pause();
    arrangement1Icons.to(iconMeshesGroup.children[0].position, { x: 0, ease: 'back.inOut(2)', duration: 0.3 }, 0);
    arrangement1Icons.to(iconMeshesGroup.children[0].position, { y: 0, ease: 'back.inOut(2)', duration: 0.3 }, 0);
    arrangement1Icons.to(iconMeshesGroup.children[0].position, { z: 0, ease: 'power1.inOut', duration: 0.3 }, 0);
    arrangement1Icons.to(iconMeshesGroup.children[1].material, { opacity: 0, duration: 0.3 }, 0.1);
    arrangement1Icons.to(iconMeshesGroup.children[1].position, { x: 0, ease: 'back.inOut(2)', duration: 0.3 }, 0);
    arrangement1Icons.to(iconMeshesGroup.children[1].position, { y: 0, ease: 'back.inOut(2)', duration: 0.3 }, 0);
    arrangement1Icons.to(iconMeshesGroup.children[1].position, { z: 0, ease: 'power1.inOut', duration: 0.3 }, 0);
    arrangement1Icons.to(iconMeshesGroup.children[2].material, { opacity: 0, duration: 0.3 }, 0.1);
    arrangement1Icons.to(iconMeshesGroup.children[2].position, { y: 0, ease: 'back.inOut(2)', duration: 0.3 }, 0);
    arrangement1Icons.to(iconMeshesGroup.children[2].position, { z: 0, ease: 'power1.inOut', duration: 0.3 }, 0);

    arrangement2MorphShapes = gsap.timeline();
    arrangement2MorphShapes.pause();
    arrangement2MorphShapes.fromTo(props, { tension: props.baseTension }, {
        tension: props.targetTension, duration: tlDurations.arrangement2MorphShapes, onUpdate: () => {
            if (!arrangement2MorphShapes.isActive()) { return; }
            square.tension = props.tension;
            const positions = getUpdatedPositions(square);
            morphShapeGeometry.setPositions(positions);
            const sc = remap(props.tension, props.baseTension, props.targetTension, props.targetTension, 1);
            morphShapesGroup.children[1].scale.set(sc, sc, sc);
            const srx = remap(props.tension, props.baseTension, props.targetTension, 0, Math.PI / 180 * 40);
            const srz = remap(props.tension, props.baseTension, props.targetTension, 0, Math.PI / 180 * 65);
            morphShapesGroup.children.forEach((s, i) => {
                const sry = Math.PI / 180 * (180 / numShapes) * i;
                s.rotation.set(srx, sry, srz);
                const so = remap(props.tension, props.baseTension, props.targetTension, (i < 2 ? 1 : 0), 0.3);
                gsap.to(s.material, { opacity: so, duration: 0 });
            });
        }, onComplete: () => {
            arrangement2MorphShapeGroupRotation.play();
            hardResetMorphShapes(true); //this is necessary because fast-scrolling doesn't always result in the correct final lerp value
        }, onReverseComplete: () => {
            hardResetMorphShapes(false); //this is necessary because calling reverse doesn't always result in the correct final lerp value
        }
    }, 0);

    arrangement2Icons = gsap.timeline();
    arrangement2Icons.pause();
    arrangement2Icons.to(iconMeshesGroup.children[0].material, { opacity: 1, duration: 0.4 }, 0.1);
    arrangement2Icons.to(iconMeshesGroup.children[0].rotation, { y: 0, duration: 0 }, 0);
    arrangement2Icons.to(iconMeshesGroup.children[0].position, { x: -0.3, ease: 'back.inOut(2)', duration: 0.8 }, 0);
    arrangement2Icons.to(iconMeshesGroup.children[0].position, { y: -0.3, ease: 'back.inOut(2)', duration: 0.8 }, 0);
    arrangement2Icons.to(iconMeshesGroup.children[1].material, { opacity: 1, duration: 0.4 }, 0.1);
    arrangement2Icons.to(iconMeshesGroup.children[1].rotation, { y: 0, duration: 0 }, 0);
    arrangement2Icons.to(iconMeshesGroup.children[1].position, { x: 0.3, ease: 'back.inOut(2)', duration: 0.8 }, 0);
    arrangement2Icons.to(iconMeshesGroup.children[1].position, { y: -0.3, ease: 'back.inOut(2)', duration: 0.8 }, 0);
    arrangement2Icons.to(iconMeshesGroup.children[2].material, { opacity: 1, duration: 0.4 }, 0.1);
    arrangement2Icons.to(iconMeshesGroup.children[2].rotation, { y: 0, duration: 0 }, 0);
    arrangement2Icons.to(iconMeshesGroup.children[2].position, { y: 0.3, ease: 'back.inOut(2)', duration: 0.8 }, 0);

    arrangement2IconsZLoop = gsap.timeline();
    arrangement2IconsZLoop.pause();
    arrangement2IconsZLoop.to(iconMeshesGroup.children[0].position, { z: 0.3, ease: 'power1.inOut', duration: 1.5, yoyo: true, repeat: -1 }, 0);
    arrangement2IconsZLoop.to(iconMeshesGroup.children[1].position, { z: 0.3, ease: 'power1.inOut', duration: 1.65, yoyo: true, repeat: -1 }, 0);
    arrangement2IconsZLoop.to(iconMeshesGroup.children[2].position, { z: 0.3, ease: 'power1.inOut', duration: 1.8, yoyo: true, repeat: -1 }, 0);

    arrangement2MorphShapeGroupRotation = gsap.timeline({ repeat: -1 });
    arrangement2MorphShapeGroupRotation.pause();
    arrangement2MorphShapeGroupRotation.to(morphShapesGroup.rotation, { y: Math.PI / 180 * 360, ease: 'none', duration: 250 });

    arrangement3MorphShapes = gsap.timeline();
    arrangement3MorphShapes.pause();
    arrangement3MorphShapes.fromTo(props, { tension: props.baseTension }, {
        tension: props.targetTension, duration: tlDurations.arrangement3MorphShapes, onUpdate: () => {
            if (!arrangement3MorphShapes.isActive()) { return; }
            morphShapesGroup.children.forEach((s, i) => {
                const sc = remap(props.tension, props.baseTension, props.targetTension, 1, 1.2);
                s.scale.set(sc, sc, sc);
                const op = remap(props.tension, props.baseTension, props.targetTension, 0.3, 0);
                s.material.opacity = op;
            });
        }
    }, 0);

    arrangement3Icons = gsap.timeline();
    arrangement3Icons.pause();
    arrangement3Icons.to(iconMeshesGroup.children[0].position, { x: ICON_X_LOC, ease: 'back.inOut(2)', duration: 0.3 }, 0);
    arrangement3Icons.to(iconMeshesGroup.children[0].position, { y: -CHECK_LOCS.y, ease: 'back.inOut(2)', duration: 0.3 }, 0);
    arrangement3Icons.to(iconMeshesGroup.children[0].position, { z: 0, duration: 0.3, yoyo: false, repeat: 0 }, 0);
    arrangement3Icons.to(iconMeshesGroup.children[0].scale, { x: 1, duration: 0.3 }, 0);
    arrangement3Icons.to(iconMeshesGroup.children[0].scale, { y: 1, duration: 0.3 }, 0);
    arrangement3Icons.to(iconMeshesGroup.children[0].scale, { z: 1, duration: 0.3 }, 0);
    arrangement3Icons.to(iconMeshesGroup.children[1].position, { x: ICON_X_LOC, ease: 'back.inOut(2)', duration: 0.8 }, 0.1);
    arrangement3Icons.to(iconMeshesGroup.children[1].position, { y: 0, ease: 'back.inOut(2)', duration: 0.8 }, 0.1);
    arrangement3Icons.to(iconMeshesGroup.children[1].position, { z: 0, duration: 0.3, yoyo: false, repeat: 0 }, 0.1);
    arrangement3Icons.to(iconMeshesGroup.children[1].scale, { x: 1, duration: 0.3 }, 0.1);
    arrangement3Icons.to(iconMeshesGroup.children[1].scale, { y: 1, duration: 0.3 }, 0.1);
    arrangement3Icons.to(iconMeshesGroup.children[1].scale, { z: 1, duration: 0.3 }, 0.1);
    arrangement3Icons.to(iconMeshesGroup.children[2].position, { x: ICON_X_LOC, ease: 'back.inOut(2)', duration: 0.8 }, 0.15);
    arrangement3Icons.to(iconMeshesGroup.children[2].position, { y: CHECK_LOCS.y, ease: 'back.inOut(2)', duration: 0.8 }, 0.15);
    arrangement3Icons.to(iconMeshesGroup.children[2].position, { z: 0, duration: 0.3, yoyo: false, repeat: 0 }, 0.15);
    arrangement3Icons.to(iconMeshesGroup.children[2].scale, { x: 1, duration: 0.3 }, 0.15);
    arrangement3Icons.to(iconMeshesGroup.children[2].scale, { y: 1, duration: 0.3 }, 0.15);
    arrangement3Icons.to(iconMeshesGroup.children[2].scale, { z: 1, duration: 0.3 }, 0.15);

    arrangement3Frames = gsap.timeline();
    arrangement3Frames.pause();
    arrangement3Frames.fromTo(meshCheck1.material, { opacity: 0 }, { opacity: 1, duration: 0.35 }, 0.5);
    arrangement3Frames.fromTo(meshCheck1.position, { y: CHECK_LOCS.y + CHECK_LOCS.off }, { y: CHECK_LOCS.y, ease: 'elastic.out(1,0.2)', duration: 1.25 }, 0.5);
    arrangement3Frames.fromTo(meshCheck2.material, { opacity: 0 }, { opacity: 1, duration: 0.35 }, 0.65);
    arrangement3Frames.fromTo(meshCheck2.position, { y: CHECK_LOCS.off }, { y: 0, ease: 'elastic.out(1,0.2)', duration: 1.25 }, 0.65);
    arrangement3Frames.fromTo(meshCheck3.material, { opacity: 0 }, { opacity: 1, duration: 0.35 }, 0.8);
    arrangement3Frames.fromTo(meshCheck3.position, { y: -CHECK_LOCS.y + CHECK_LOCS.off }, { y: -CHECK_LOCS.y, ease: 'elastic.out(1,0.2)', duration: 1.25 }, 0.8);
    arrangement3Frames.fromTo(framesGroup.children[0].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 0.6);
    arrangement3Frames.fromTo(framesGroup.children[1].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 0.9);
    arrangement3Frames.fromTo(framesGroup.children[2].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 1.1);
    arrangement3Frames.fromTo(framesGroup.children[0].position, { x: FRAME_D + ICON_X_LOC }, { x: (FRAME_D + ICON_X_LOC) * FRAME_SCALE, duration: 0.4 }, 0.6);
    arrangement3Frames.fromTo(framesGroup.children[1].position, { x: FRAME_D + ICON_X_LOC }, { x: (FRAME_D + ICON_X_LOC) * FRAME_SCALE, duration: 0.4 }, 0.9);
    arrangement3Frames.fromTo(framesGroup.children[2].position, { x: FRAME_D + ICON_X_LOC }, { x: (FRAME_D + ICON_X_LOC) * FRAME_SCALE, duration: 0.4 }, 1.1);
    arrangement3Frames.fromTo(framesGroup.children[0].scale, { x: 1 }, { x: FRAME_SCALE, duration: 0.4 }, 0.6);
    arrangement3Frames.fromTo(framesGroup.children[1].scale, { x: 1 }, { x: FRAME_SCALE, duration: 0.4 }, 0.9);
    arrangement3Frames.fromTo(framesGroup.children[2].scale, { x: 1 }, { x: FRAME_SCALE, duration: 0.4 }, 1.1);
    arrangement3Frames.fromTo(frameLinesGroup.children[0].children[0].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 0.65);
    arrangement3Frames.fromTo(frameLinesGroup.children[1].children[0].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 0.95);
    arrangement3Frames.fromTo(frameLinesGroup.children[2].children[0].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 1.15);
    arrangement3Frames.fromTo(frameLinesGroup.children[0].children[1].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 0.66);
    arrangement3Frames.fromTo(frameLinesGroup.children[1].children[1].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 0.96);
    arrangement3Frames.fromTo(frameLinesGroup.children[2].children[1].material, { opacity: 0 }, { opacity: 1, duration: 0.4 }, 1.16);

    arrangement3FramesHide = gsap.timeline();
    arrangement3FramesHide.pause();
    arrangement3FramesHide.to(meshCheck1.material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(meshCheck2.material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(meshCheck3.material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(framesGroup.children[0].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(framesGroup.children[1].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(framesGroup.children[2].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(frameLinesGroup.children[0].children[0].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(frameLinesGroup.children[1].children[0].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(frameLinesGroup.children[2].children[0].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(frameLinesGroup.children[0].children[1].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(frameLinesGroup.children[1].children[1].material, { opacity: 0, duration: 0.25 }, 0);
    arrangement3FramesHide.to(frameLinesGroup.children[2].children[1].material, { opacity: 0, duration: 0.25 }, 0);
}

const clearArrangement1Loops = () => {
    arrangement1SquareLoop.progress(0);
    arrangement1SquareLoop.pause();
    if (arrangement1Icon0Loop.isActive()) {
        iconIndex = arrangement1Icon0Loop.progress() < 0.5 ? 0 : 1;
        arrangement1Icon0Loop.progress(arrangement1Icon0Loop.progress() < 0.5 ? 0 : 1);
        arrangement1Icon0Loop.pause();
    }
    else if (arrangement1Icon1Loop.isActive()) {
        iconIndex = arrangement1Icon1Loop.progress() < 0.5 ? 1 : 2;
        arrangement1Icon1Loop.progress(arrangement1Icon1Loop.progress() < 0.5 ? 0 : 1);
        arrangement1Icon1Loop.pause();
    }
    else if (arrangement1Icon2Loop.isActive()) {
        iconIndex = arrangement1Icon2Loop.progress() < 0.5 ? 2 : 0;
        arrangement1Icon2Loop.progress(arrangement1Icon2Loop.progress() < 0.5 ? 0 : 1);
        arrangement1Icon2Loop.pause();
    }
    updateOpacityItems(true);
}

const hardResetMorphShapes = (toEnd) => {
    square.tension = toEnd ? props.targetTension : props.baseTension;
    const positions = getUpdatedPositions(square);
    morphShapeGeometry.setPositions(positions);
    if (toEnd) {
        morphShapesGroup.children.forEach((s, i) => {
            const sry = Math.PI / 180 * (180 / numShapes) * i;
            s.rotation.set(Math.PI / 180 * 40, sry, Math.PI / 180 * 65);
            s.scale.set(1,1,1);
        });
    }
    else {
        morphShapesGroup.children.forEach((s, i) => {
            s.rotation.set(0, 0, 0);
            s.scale.set(1,1,1);
            if (i == 1) { s.scale.set(props.targetTension, props.targetTension, props.targetTension); }
            else if (i > 1) { s.material.opacity = 0; }
        });
        arrangement2MorphShapeGroupRotation.progress(0);
        arrangement2MorphShapeGroupRotation.pause();
    }
}

const getUpdatedPositions = (source, divisions = 1500) => {
    const point = new THREE.Vector3();
    const positions = [];
    for (let i = 0, l = divisions; i < l; i++) {
        const t = i / l;
        source.getPoint(t, point);
        positions.push(point.x, point.y, point.z);
    }
    return positions;
}

const updateOpacityItems = (forBeginning) => {
    opacityItems = [];
    if (forBeginning) {
        opacityItems.push(morphShapesGroup.children[0]);
        opacityItems.push(morphShapesGroup.children[1]);
        opacityItems.push(iconMeshesGroup.children[iconIndex]);
    }
    else {
        iconMeshesGroup.children.forEach(c => { opacityItems.push(c); });
        framesGroup.children.forEach(c => { opacityItems.push(c); });
        frameLinesGroup.children.forEach(c => { c.children.forEach(sc => { opacityItems.push(sc); }) });
        opacityItems.push(meshCheck1);
        opacityItems.push(meshCheck2);
        opacityItems.push(meshCheck3);
    }
}

const setOpacity = (o) => {
    opacityItems.forEach(e => { e.material.opacity = o; });
}

window.addEventListener("resize", () => {
    handleMobile();
});

const MOBILE_BREAK = 756;
const isMobile = () => { return window.innerWidth < MOBILE_BREAK; }
const handleMobile = () => {
    uberGroup.position.x = isMobile() ? 0.85 : 0;
    containerGroup.position.y = isMobile() ? 0.3 : 0;
}